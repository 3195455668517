/* ----------------------- Styles globaux ----------------------- */

/* Défilement plus doux */
html {
    scroll-behavior: smooth;
  }
  
/* Styles pour les titres */
h1, h2, h3, p {
    margin: 0;
}

.TitreDiv{
    text-align: center;
    font-family: 'Fredoka One', sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
    background: linear-gradient(to bottom, #8B9DD1, #9B9DC2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}

.Body{
    width: 100%;
    height: 100%;
    background-color: white;
}
/* ----------------------- Styles Offre ----------------------- */
.Head {
    position: relative;
    background: 
        linear-gradient(180deg, #51535D 0%, rgba(44, 44, 44, 0.114) 100%),
        url('../assets/Saturn.gif');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    min-height: 80vh;
    overflow: hidden;
}

.Head::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: inherit;
    filter: blur(10px);
    width: 100%;
    height: 120%;
}

.content-head{
    position: relative;
    z-index: 999;
    padding: 5vh 15vh 15vh 15vh;
    height: 55vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 5vh;
}

.TitreHead{
    color: #ECECEC;
    font-family: 'Fredoka One', sans-serif;
    font-size: 56px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -2.76px;
}

ul{
    color: #1A1A1A;
    font-size: 21px;
    font-style: normal;
    list-style: none;
    font-weight: 400;
    line-height: 39px;
}

.highlight {
    font-weight: 600;
    color: white;
}



/* Styles for screens smaller than 1200px */
@media only screen and (max-width: 1200px) {
    .TitreHead {
        font-size: 48px;
    }
    ul {
        font-size: 19px;
    }

}


/* Styles for screens smaller than 992px */
@media only screen and (max-width: 992px) {
    .TitreHead {
        font-size: 40px;
    }
    ul {
        font-size: 14px;
    }
    .TitreDiv{
        font-size: 38px;
    }

    .content-head{
        padding: 5vh 10vh 10vh 10vh;
    }
}

/* Styles for screens smaller than 768px */
@media only screen and (max-width: 768px) {
    .TitreHead {
        font-size: 32px;
    }
    ul {
        font-size: 12px;
    }
    .content-head{
        padding: 5vh 5vh 15vh 5vh;
    }
    .TitreDiv{
        font-size: 30px;
    }
}

/* Styles for screens smaller than 576px */
@media only screen and (max-width: 576px) {
    .TitreHead {
        font-size: 32px;
        letter-spacing: 0.15px;
        text-align: center;
    }
    ul {
        font-size: 12px;
        list-style: none;
        text-align: center;
        padding-left: 0;
        display: flex;
        flex-direction: column;
        gap: 10px;
        font-weight: 500;

    }
    .content-head{
        align-items: center;
        height: 46vh;
    }

    .TitreDiv {
        font-size: 30px;
        padding: 0 20px;
    }

}

/* Styles for screens smaller than 576px */
@media only screen and (max-width: 375px) {
    .TitreHead {
        font-size: 32px;
        letter-spacing: 0.15px;
        text-align: center;
    }
    ul {
        font-size: 12px;
        list-style: none;
        text-align: center;
        padding-left: 0;
        display: flex;
        flex-direction: column;
        gap: 10px;
        font-weight: 500;

    }
    .content-head{
        margin-top: 8vh;
        height: 60vh;
    }

    .TitreDiv {
        font-size: 30px;
        padding: 0 20px;
    }

}


/* ----------------------- Styles Offre ----------------------- */
.Offre{
    margin: 32px 0px 32px 0px;
    background-color: rgb(255, 255, 255);
}

.offre-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }

  .div-sphere{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Sphere{
    width: 330px;
    height: 330px;
  }
  
/* ----------------------- Styles A propos ----------------------- */
.Apropos{
    padding: 32px 10%;
    background-color: #51535D;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.TextPropos{
    margin-top: 16px;
    color: #FFF;
text-align: center;
font-size: 21.507px;
font-style: normal;
font-weight: 700;
line-height: 37.637px;
}


/* ----------------------- Styles Equipe ----------------------- */
/* ----------------------- Styles Equipe ----------------------- */
.Equipe{
    margin: 32px 0px 32px 0px;
}

.DivEquipe{
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

/* When the screen size is 768px or less, set the direction to column */
@media screen and (max-width: 768px) {
    .DivEquipe{
        flex-direction: column;
        align-items: center;
    }

    .Sphere{
        width: 220px;
        height: 220px;
      }
}

/* ----------------------- Styles Methodologie ----------------------- */

.Methodologie{
    background-color: #51535D;
    padding: 32px 0 32px 0;
}

.metho12{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.metho34{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

/* When the screen size is 768px or less, set the direction to column */
@media screen and (max-width: 768px) {
    .metho12{
        flex-direction: column;
    }
    
    .metho34{
        flex-direction: column;
    }

    .TextPropos {
        font-size: 18px;
    }
}

.Contact{
    margin: 32px 0px;
}

.DivForm{
    padding: 16px;
}

.DivForm2{
    display: flex;
    flex-direction: row;
}

.DivForm3{
    width: 100%;
    padding: 16px;
}

.DivForm4{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.message-treated {
    color: rgb(255, 255, 255);
  }
  
  .message-untreated {
    color: #51535D;
  }
  

  .UnTreateDiv {
    padding: 16px;
    margin: 16px;
    position: relative;
    border-radius: 16px;
    border: 1px solid #1F1C1D;
    box-shadow: 0px 1px 4px -2px rgba(0, 0, 0, 0.06), 0px 4px 8px -2px rgba(0, 0, 0, 0.10);
    transition: transform 0.5s ease;
}

.Messnontraite {
    background-color: #ffffff;
    padding: 32px 0 32px 0;
}

.Messtraite {
    background-color: #51535D;
    padding: 32px 0 32px 0;
}


.GeneralMessages {
    background-color: #51535D;;
}

.Retour{
    display: flex;
    justify-content: center;
    padding: 2vh;
}