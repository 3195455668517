.offre-card {
    padding: 16px;
    margin: 16px;
    flex: 1 0 300px;
    max-width: 400px;
    position: relative;
    border-radius: 16px;
    border: 1px solid #D2D6DB;
    background: rgba(255, 255, 255, 0.00);
    box-shadow: 0px 1px 4px -2px rgba(0, 0, 0, 0.06), 0px 4px 8px -2px rgba(0, 0, 0, 0.10);
    transition: transform 0.5s ease;
}

.offre-card:hover {
    transform: scale(1.03);
}

  .offre-title {
    padding: 0px 20%;
    align-items: center;
    margin-bottom: 10px;
    text-align: center;
    font-family: 'Fredoka One', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    background: linear-gradient(to bottom, #8B9DD1, #9B9DC2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

  }
  
  .offre-text {
    padding: 0 10%;
    color: #363233;
text-align: center;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px;
    text-align: center;
  }
  