.footer {
    display: flex;
    justify-content: space-between;
    padding: 20px 10%;
    flex-direction: column;
    background-color: #e4e8ff;
  }
  
.footer__contact_2{
  display: flex;
  flex-direction: column;
}

  .footer__top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .footer__left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .footer__logo {
    max-width: 150px;
  }
  
  .footer__right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .footer__contact {
    display: flex;
    width: 100%;
    gap: 64px;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px;
  }
  
  .footer__contact > div {
    display: flex;
    flex-direction: column;
  }
  
  .footer__bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    text-align: center;
  }
  
  .footer__links a {
    margin-left: 20px;
    text-decoration: none;
    color: inherit;
  }
  
  /* Responsive Design */
  @media only screen and (max-width: 768px) {
    .footer__top {
      flex-direction: column;
    }
  
    .footer__right,
    .footer__left {
      align-items: center;
    }
  
    .footer__contact {
        flex-direction: column;
        gap: 20px;
        align-items: center;
        text-align: center;
        padding: 16px;
    }
  }
  

  .footer a {
    text-decoration: none; /* Supprime le soulignement */
    color: inherit; /* Hérite la couleur du texte du parent */
  }
  
  /* Pour gérer le hover et le focus */
  .footer a:hover,
  .footer a:focus {
    text-decoration: underline; /* Ajoute le soulignement lorsque l'utilisateur passe la souris ou sélectionne le lien */
  }
  
  /* Pour gérer le visited links */
  .footer a:visited {
    color: inherit; /* Les liens visités seront de la même couleur que les liens normaux */
  }