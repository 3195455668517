.my-button {
    background: linear-gradient(to bottom, #8B9DD1, #9B9DC2);

    border: none;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 29px;
    padding: 16px 24px; /* espace autour du texte */
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 8px; /* arrondit les coins du bouton */
    transition-duration: 0.4s; /* effet de transition lors du survol */
    max-width: 165px;
    box-sizing: border-box;
}

.my-button:hover {
    background: linear-gradient(180deg, #7a90d0 0%, #878ac7 100%);
    color: rgb(255, 255, 255); /* couleur du texte lors du survol */
}

/* Styles pour les écrans de moins de 1200px */
@media only screen and (max-width: 1200px) {
    .my-button {
        font-size: 16px;
        padding: 14px 20px;
        max-width: 150px;
    }
}

/* Styles pour les écrans de moins de 992px */
@media only screen and (max-width: 992px) {
    .my-button {
        font-size: 14px;
        padding: 12px 18px;
        max-width: 130px;
    }
}

