.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2em 10%;
    position: inherit;
    height: 5vh;
    z-index: 1000;
}

.header img {
    height: 100px;
    width: 150px;
}

.header nav {
    display: flex;
    align-items: center;
}

.header a {
    margin-right: 1em;
    color: white;
    text-decoration: none;
}

@media (max-width: 576px) {
    .header {
        justify-content: center;
    }

    .header img {
        display: block;
    }

    .header nav {
        display: none;
    }
}
